<template>
  <div class="guide-cnt6">
    <h6 class="h6">签收与验货</h6>
    <div class="cnt">
      <p class="p">为了保护您的权益，微微定建议您在收货时注意以下几点：</p>
      <p class="p">1)建议由您本人亲自签收，若委托他人代签收，而配送人员不在的情况下验收，出现一切问题，需自己承担;</p>
      <p class="p">2)签收时，请与配送人员当面核对：产品名称、应付金额、产品数量及发货清单、发票等，若在验货时，发现产品破损、产品错误、
产品短缺、印刷质量等问题，可直接拨打400-6070-818与微微定客服沟通.</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'guide-cnt6',
  props: {
    msg: String
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.guide-cnt6 {
  width: 946px;float:right;background: #fff;border-radius: 5px;min-height: 600px;
  .h6 {
    height: 68px;border-bottom:1px solid #f2f2f2;font-weight: normal;font-size: 22px;line-height: 68px;padding-left:20px;
  }
  .cnt {
    padding:15px;
    .p {
      font-size: 14px;color:#333;margin-bottom:10px;
    }
  }
}
</style>
